import React from "react"
import { Button } from "@mui/material"
import FormContainer from "./../../basic/FormContainer"
import FinalConfirmationDialog from "./FinalConfirmationDialog"
import {getCaseData} from "../../../caseDataType"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import TagManager from 'react-gtm-module';
import { saveCaseData } from '../../../requests'

const CLAIM_URL = process.env.REACT_APP_CLAIM_URL

function FinishButtonPage(props: {}) {
  const token = useAppSelector(state => state.composition.reqToken)
  const { isValidated } = useAppSelector(state => state.accountNumber)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const caseData = getCaseData(useAppSelector)

  const handleConfirm = async () => {
    
    // Make request
    const res = await saveCaseData(caseData, token)
    const callSuccess = res.ok ? "success" : "failure"
    TagManager.dataLayer({
      dataLayer: {
        event: "save_case_data_call",
        callSuccess: callSuccess
      }
    })

    TagManager.dataLayer({
      dataLayer: {
        event: "manual_event_SUBMIT_LEAD_FORM",
      }
    })

    if (res.ok) {
        // Redirect to case status page
        window.location.replace(`${CLAIM_URL}/case-status?t=${res.val}`)

    } else {
        alert(res.val.message)
    }
  }

  return <FormContainer willScroll>
            <FinalConfirmationDialog confirm={handleConfirm} isOpen={confirmOpen} handleClose={() => setConfirmOpen(false)} />
            <Button variant="contained"
                    disabled={!isValidated}
                    onClick={() => {
                     setConfirmOpen(true) 
                    }}>
                  Start sag
            </Button>
         </FormContainer>
}

export default FinishButtonPage
