import BlurredDialog from "../../basic/BlurredDialog"
import React from "react"
import { Link } from "@mui/material"


// Props
interface TermsDialogProps {
    flightNumber: string
    flightDate: string
    paxPayoutCompensation: number
    fullCompensationAmount: number
    feeAmount: number
    paxName: string
}

function TermsDialog(props:TermsDialogProps) {
    const [open, setOpen] = React.useState(false);
    
    // Replace fetch with direct template usage
    const contractHtml = CONTRACT_HTML_TEMPLATE
        .replace(/{flightNumber}/g, props.flightNumber)
        .replace(/{flightDate}/g, props.flightDate)
        .replace(/{paxPayoutCompensation}/g, props.paxPayoutCompensation.toString())
        .replace(/{fullCompensationAmount}/g, props.fullCompensationAmount.toString())
        .replace(/{feeAmount}/g, props.feeAmount.toString())
        .replace(/{paxName}/g, props.paxName);

    return (
        <div className={"inline"}>
            <Link
                onClick={()=>{setOpen(true)}}
                component={"button"}
                // @ts-ignore
                variant={props.variant}>Kontrakt</Link>
            <BlurredDialog
                title={'Kontrakt'}
                open={open}
                handleClose={() => {setOpen(false)}}
            >
                <div dangerouslySetInnerHTML={{ __html: contractHtml }} />
            </BlurredDialog>
        </div>
    )
}

export const CONTRACT_HTML_TEMPLATE = `
<p><strong>Kontrakt mellem passageren og FlySag</strong></p>
<p><strong>1 Anvendelsesområde</strong><br>1.1 Vilkår og betingelser i denne aftale gælder mellem {paxName} (herefter benævnt "passageren") og FlySag ApS (herefter benævnt &quot;FlySag&quot;), og omfatter passagerens krav efter EU-forordning nr. 261/2004 i anledning af reservationen til {flightNumber} den {flightDate}.</p>
<p><strong>2 Aftalens indgåelse og accept</strong><br>2.1 Passageren bekræfter ved sin underskrift at have læst og forstået aftalens vilkår og betingelser.</p>
<p><strong>3 Betaling til passageren</strong><br>3.1 Passagerens krav mod flyselskabet overdrages til eje til FlySag, når flyselskabet enten selv har erkendt at skylde kompensationen eller er blevet dømt til at betale kompensationen.</p>
<p>3.2 Som modydelse for overdragelsen af kravet udbetaler FlySag {paxPayoutCompensation} kr. til den ved sagsoprettelsen indtastede bankkonto. Betalingen skal - ligesom kravets overdragelse - ske, når flyselskabet har erkendt at skylde kompensationen eller er blevet dømt til at betale kompensationen. </p>
<p>3.3 Beløbet på {paxPayoutCompensation} kr. er opgjort som passagerens forventede kompensation på {fullCompensationAmount} kr. fratrukket FlySags salær på {feeAmount} kr.</p>
<p>3.4 Hvis det viser sig, at passageren er berettiget til et lavere kompensationsbeløb end det i pkt. 3.3 nævnte, er FlySag alene forpligtet til at udbetale dette lavere beløb, fratrukket salær, der reduceres med samme procentsats som kompensationsbeløbet.</p>
<p>3.5 Hvis sagen kommer for retten, afholder FlySag alle omkostninger og gebyrer i forbindelse hermed. Eventuelt tilkendte beløb, som ikke udgør kompensation eller refusion, herunder f.eks. sagsomkostninger og de på kravet påløbne renter, tilkommer FlySag.</p>
<p><strong>4 Tro og love erklæring</strong><br>4.1 Passageren bekræfter ved sin underskrift af denne kontrakt, at de oplysninger, som passageren har givet FlySag, er korrekte, retvisende og fyldestgørende.</p>
<p>4.2 Passageren er på anmodning fra FlySag forpligtet til at fremsende kopier til FlySag af al korrespondance med flyselskabet, herunder korrespondance mellem eventuelle medpassagerer i sagen og flyselskabet.</p>
<p>4.3 Ved underskrift af aftalen bekræfter passageren, at kravet hverken er udbetalt eller under inddrivelse af passageren selv, advokat, eventuelle medpassagerer, nogen af FlySags konkurrenter eller nogen andre. Passageren garanterer samtidig, at hverken passageren eller eventuelle medpassagerer selv vil tage skridt til at inddrive kravet direkte fra flyselskabet eller oprette sagen hos nogen advokat, nogen af FlySags konkurrenter eller nogen andre.</p>
<p>4.4 Såfremt passageren modtager betaling direkte fra flyselskabet, er passageren forpligtet til at overføre det i pkt. 3.3 nævnte salær til FlySag, evt. nedsat i overensstemmelse med pkt. 3.4.</p>
<p><strong>5 Kommunikation med flyselskabet</strong><br>5.1 Efter oprettelse af sagen hos FlySag, forpligter passageren sig til ikke at have kontakt med flyselskabet vedrørende kravet og sagen, da al korrespondance herom fremover håndteres af FlySag.</p>
<p>5.2 Såfremt flyselskabet eller dets repræsentanter overfører penge til eller kontakter passageren eller eventuelle medpassagerer, efter at sagen er oprettet hos FlySag, er passageren forpligtet til omgående at give FlySag besked herom.</p>
<p><strong>6 Fortrydelse</strong><br>6.1 Hvis passageren er forbruger, fraskriver passageren sig sin fortrydelsesret, idet FlySag umiddelbart efter passagerens underskrift af aftalen påbegynder inddrivelsen af kravet på vegne af passageren.</p>
<p><strong>7 Misligholdelse</strong><br>7.1 Misligholder passageren aftalen, eller bevirker passagerens forhold på anden vis, at inddrivelsen ikke kan gennemføres, er FlySag berettiget til at opkræve passageren det i pkt. 3.3-3.4 nævnte salær for FlySags arbejde på sagen, selv om kompensationen ikke er inddrevet. </p>
<p>7.2 Dette vil eksempelvis - men ikke udelukkende - være tilfældet, hvis: </p>
<ol>
<li>kravet inddrives andetsteds, f.eks. af en medpassager, en advokat eller en af FlySags konkurrenter  </li>
<li>passageren allerede har modtaget betaling af kravet på tidspunktet for sagsoprettelse  </li>
<li>passageren udenom FlySag har kontakt med flyselskabet om kravet eller betalingen  </li>
<li>passageren har afgivet forkert, misvisende eller mangelfuld information til FlySag  </li>
<li>passageren ikke straks sender oplysninger af betydning for sagen til info@flysag.dk</li>
</ol>
<p>7.3 I tilfælde af passagerens misligholdelse af aftalen, har FlySag ret men ikke pligt til med omgående virkning at afstå fra yderligere behandling af sagen og udtræde af en eventuel verserende retssag.</p>
<p><strong>8 Fortrolighed og persondata</strong><br>8.1 Oplysninger udvekslet mellem FlySag og passageren skal behandles fortroligt og i overensstemmelse med gældende lovgivning i Danmark, herunder databeskyttelsesloven og databeskyttelsesforordningen. Bortset fra hvad der er nødvendigt som følge af behandlingen af sagen, videregives oplysninger ikke til tredjemand.</p>
<p>8.2 FlySag behandler som dataansvarlig al data fra passageren i henhold til FlySags persondatapolitik, som kan findes på <a href="https://flysag.dk/privacy-policy/">https://flysag.dk/privacy-policy/</a> og kan fremsendes efter anmodning.</p>
<p>8.3 Passageren har ikke ret til helt eller delvist at videregive materiale fra eller kommunikation med FlySag til tredjemand.</p>
<p>8.4 Passageren giver FlySag ret til at omtale passagerens sag med henblik på markedsføring, så længe omtalen udelukkende angår flyvningen, sagens resultat og passagerens fornavn.</p>`

export default TermsDialog;
