import React, { useEffect, useState } from "react"
import { Typography
       , Grid
       , Container 
       , Stepper
       , Step
       , StepLabel 
       , StepContent
       , Divider
       , Button
       } from "@mui/material"

import axios from "axios"

import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports'
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { ColorlibStepIcon } from "../basic/ColorizedStepper"
import {useSearchParams} from "react-router-dom";

import { getCassesApi } from "../../requests"
import { StepIconProps } from '@mui/material/StepIcon';

// FIXME: Move to proper file..

function InfoStepIcon(props: StepIconProps) {
return (
    <InfoIcon color="primary" fontSize="large"/>
)
}

type Case = any | null
function PaxStatusPage(props: {}) {
    const [caseData, setCaseData] = useState<Case>(null)
    const [status, setStatus] = useState<Array<{label: string, description: string}> | null>(null)
    const [passengers, setPassengers] = useState<Array<any> | null>(null)
    const [owner, setOwner] = useState<any>(null)

    const [urlParams] = useSearchParams();
    let token = urlParams.get("t")
    let linkExpired = false

    useEffect(() => {
        if (token !== null) {
            getCassesApi(token)
                .then((data) => { 
                    setCaseData(data.case)
                    setStatus(data.status)
                    setPassengers(data.passengers)
                    setOwner(data.owner)
                })
                .catch((err) => {
                    console.error(err)
                })
        }
    }, [token])

    return <div className="compositionPage">
               <Container className="header-push-down">
               { caseData === null &&
                   <Typography>Ugyldigt link</Typography>
               }
               { caseData !== null &&
                 <div style={{background: "#fff", padding: "12px", borderRadius: "10px"}}>
                     <Typography variant="h3">{caseData.wrongedDepAirport} <ConnectingAirportsIcon fontSize="large" /> {caseData.wrongedArrAirport}</Typography>
                     <Typography variant="h5">FS{caseData.caseId}</Typography>
                     {/* <Divider /> */}
                     <Grid container>

                         <Grid item md={7} xs={12} marginBottom={2}>
                             {/* <Stepper activeStep={status.length - 1} 
                                      orientation="vertical"
                                      style={{marginLeft: "10%"}}
                                      >
                                        {status.map((step, index) =>   */}
                                          <Step key={1}>
                                              {/*FIXME: Find a less retarded way of making this bigger*/}
                                              <StepLabel StepIconComponent={InfoStepIcon}>
                                                  {/* {step.label} */}
                                                  {/* <InfoIcon /> */}
                                              </StepLabel>
                                              <StepContent>
                                                <Typography align="left">
                                                    {/* Vi har modtaget alt, vi skal bruge fra dig, og er nu ved at gennemgå din sag.
                                                    Hvis alt stemmer overens og vi godkender din sag 
                                                    vil alle pasagere som blev tilføjet til sagen, 
                                                    modtage en e-mail med en aftale, som de skal underskrive med MitID.
                                                    Efter alt er blevet underskrevet, vil vi overføre kompensationen fratrukket vores salær via NemKonto. */}
                                                    Vi har modtaget dine oplysninger, og er nu ved at gennemgå din sag. Efter vores gennemgang af sagen, modtager du en e-mail med vores endelige bekræftelse eller afvisning.
                                                </Typography>
                                              </StepContent>
                                          </Step>
                                        {/* )} */}
                             {/* </Stepper> */}
                         </Grid>
                         <Grid item xs={0} md={1}><Divider orientation="vertical"/></Grid>
                         {/* <Grid item xs={12} md={0}><Divider sx={{marginBottom: 2}} /></Grid> */}
                         <Grid item md={4} xs={12}>
                            <Grid spacing={1} container sx={{ overflowX: "scroll" }}>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">{owner.firstName} {owner.lastName}</Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">{owner.email}</Typography>
                                </Grid>
                                { passengers !== null &&
                                <>
                                <Grid item md={12} xs={12}>
                                    <Typography variant="body1">Antal passagerer: {passengers.length}</Typography>
                                </Grid>
                                </>
                                }
                            </Grid>
                         </Grid>
                     </Grid>
                 </div>
               }
               </Container>
           </div>
}

export default PaxStatusPage
