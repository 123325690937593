import React, { useState } from "react"
import FormContainer from "../../basic/FormContainer"
import { CircularProgress, Collapse, Grid, Typography } from "@mui/material"
import SignatureComponent from "./SignatureComponent"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { setSigned } from "./SignatureSlice"
import { setPax } from "../ContactPage/ContactSlice"
import { saveSignatureReq } from "../../../requests"
import HeaderTypography from "../../basic/HeaderTypography"

const SignaturePage = (props:{}) => {
    const [index, setIndex] = React.useState(0)
    const paxArray = useAppSelector(state => state.contact.passengers)
    const token = useAppSelector(state => state.composition.reqToken)
    const caseId = useAppSelector(state => state.composition.caseId)
    const paxIds = useAppSelector(state => state.composition.paxIds)
    const [loadingIndicator, setLoading] = React.useState(false)
    const dispatch = useAppDispatch()

    const saveSignature = async (url: string) => {
        setLoading(true)
        // Create a new array of the old array where the signature has been changed
        const newPaxArray = paxArray.map((p, i) => i === index ? { ...p, signature: url } : p)
        dispatch(setPax(newPaxArray))
        setIndex(index + 1)
        if (index >= paxArray.length - 1)
          dispatch(setSigned(true))
        setLoading(false)
    }

    return <FormContainer willScroll>
        {index < paxArray.length &&
            <Grid container spacing={4} alignItems='center' justifyContent='center'>
                <Grid item md={2}>
                    { loadingIndicator && <CircularProgress /> }
                </Grid>
                <Grid item md={12}>
                    <HeaderTypography>Vi har brug for fuldmagter for alle personer</HeaderTypography>
                </Grid>
                <Grid item md={12}>
                    <Typography variant="body1">Underskrift for {paxArray[index].firstName} {paxArray[index].lastName}   ({index+1}/{paxArray.length})</Typography>
                </Grid>
                <Grid item md={12}>
                    <SignatureComponent onSave={saveSignature} disabled={loadingIndicator} pax={paxArray[index]}/>
                </Grid>
            </Grid>
            }
        <Collapse in={index >= paxArray.length}>
            <HeaderTypography>Alle personer har underskrevet</HeaderTypography>
        </Collapse>
    </FormContainer>
}

export default SignaturePage
