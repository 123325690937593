import BlurredDialog from "../../basic/BlurredDialog"
import React from "react"
import { Link } from "@mui/material"


// Props
interface POADialogProps {
    flightNumber: string
    flightDate: string
    paxPayoutCompensation: number
    fullCompensationAmount: number
    feeAmount: number
    paxName: string
}

function POADialog(props:POADialogProps) {
    const [open, setOpen] = React.useState(false);
    
    // Replace fetch with direct template usage
    const contractHtml = CONTRACT_HTML_TEMPLATE
        .replace(/{flightNumber}/g, props.flightNumber)
        .replace(/{flightDate}/g, props.flightDate)
        .replace(/{paxPayoutCompensation}/g, props.paxPayoutCompensation.toString())
        .replace(/{fullCompensationAmount}/g, props.fullCompensationAmount.toString())
        .replace(/{feeAmount}/g, props.feeAmount.toString())
        .replace(/{paxName}/g, props.paxName);

    return (
        <div className={"inline"}>
            <Link
                onClick={()=>{setOpen(true)}}
                component={"button"}
                // @ts-ignore
                variant={props.variant}>Fuldmagt</Link>
            <BlurredDialog
                title={'Fuldmagt'}
                open={open}
                handleClose={() => {setOpen(false)}}
            >
                <div dangerouslySetInnerHTML={{ __html: contractHtml }} />
            </BlurredDialog>
        </div>
    )
}

export const CONTRACT_HTML_TEMPLATE = `<p><strong>Fuldmagt til at føre din sag</strong></p>
<p>For at vi kan føre din sag, skal du underskrive fuldmagten nedenfor. Vi bruger fuldmagten til at bevise over for flyselskabet, og om nødvendigt senere domstolene, at vi har fået tilladelse til at inddrive dit krav:<br>_________________________________________________________________________</p>
<p>Jeg, {paxName}, giver hermed:</p>
<p>FlySag Aps (herefter benævnt &quot;FlySag&quot;)<br>CVR: DK 43847880<br>Adresse: Emdrupvej 173 st tv, 2400 København NV</p>
<p>Bemyndigelse til at handle på mine vegne under nedenstående vilkår: </p>
<ol>
<li><p>Med denne fuldmagt giver jeg FlySag tilladelse til at fremsætte og inddrive mit krav efter EU-forordning nr. 261/2004 i anledning af reservationen til {flightNumber} den {flightDate}. Herved giver jeg også tilladelse til, at FlySag må håndtere og modtage indbetalinger fra skyldner og føre sagen ved enhver dansk domstol på mine vegne.  </p>
</li>
<li><p>Denne fuldmagt berettiger FlySag til at foretage ethvert inden- og udenretligt skridt med henblik på inddrivelse af kompensation/refusion, herunder anlægge retssag, indgå forlig, akkorder og andre aftaler for at sikre udbetaling af passagerens krav. De enkelte skridt i inddrivelsen af passagerens krav kræver ikke passagerens specifikke accept, idet passagerens accept er indeholdt i denne fuldmagt.  </p>
</li>
<li><p>Jeg accepterer desuden, at al kommunikation med flyselskabet vedrørende sagen skal foregå gennem FlySag.  </p>
</li>
<li><p>Denne fuldmagt berettiger ikke til, at FlySag handler på mine vegne i andre henseender end de ovenfor beskrevne.</p>
</li>
<li><p>Jeg bekræfter, at alle oplysninger, som jeg har givet FlySag, er korrekte, retvisende og fyldestgørende.</p>
</li>
</ol>`

export default POADialog;
