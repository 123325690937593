import axios from "axios"
// import { Result } from "./utils"
import { Ok, Err, Result } from "ts-results"
import { File } from "./components/basic/UploadFileComponent"
import { AutocompleteOption as AirportResponse } from "./components/flow/RouteInfo/RouteInput"

const ROOT_URL = process.env.REACT_APP_ROOT_URL

const getCaseId = async (): Promise<Result<{caseId: string, token: string}, Error>> => {
    const reqUrl = ROOT_URL + '/start-case'
    try {
        const res = await axios.get(reqUrl)
        if (res.status === 200) 
            return Ok({ caseId: res.data.caseId, token: res.data.token })
        else return Err(new Error("Server error")) 
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}


// Save data request
// FIXME: Find the proper types for both the ok value and the err value
// FIXME: Is propably a Case type that should be in enums
async function saveCaseData(data: any, token:string): Promise<Result<Array<any>, Error>> {
    // Make request
    try {
        const res = await axios.post(ROOT_URL + '/save-case-data?secret_token=' + token, { caseData: data })
        if (res.status === 200) {
            return Ok(res.data)
        } else {
            return Err(new Error("Server error"))
        }
    } catch (err: any) {
        console.error(err)
        return Err(err)
    }
}

const sendUploadRequest = async (files: Array<File>, docType:string, caseId:string, token:string): Promise<Result<null, Error>> => {
    // Create data object
    const data = { caseId, docType, fileNames: files.map((file) => file.name) }
    // Create request url with token
    const reqUrl = ROOT_URL + '/get-upload-urls?secret_token=' + token
    // Make request
    try {
        // Step 1: Send request to server to get signed url
        const res = await axios.post(reqUrl, data)
        // Step 2: Upload file to signed url
        // Get signed urls
        const signedUrls = res.data.signedUrls
        // Upload files
        const promises = files.map((file, i) => {
            const signedUrl = signedUrls[i]
            return axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            })
        })
        await Promise.all(promises)
        return Ok(null)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

// FIXME: It is the same as AutocompleteOptions from AddstopOver
// interface AirportResponse {
    // name: string,
    // iata: string,
    // country: string,
    // city: string,

    // // name: string,
    // // iata: string,
    // // country: string,
    // // city: string,
    // // countryCode: string, // FIXME: Are we garanteed this is here?
    // // ec261: boolean // FIXME: Are we garanteed this is here?
// }

const searchAirports = async (query: string): Promise<Result<Array<AirportResponse>, Error>> => {
    // @ts-ignore
    if (query.length < 3 || query.length === 0 || query === 0) {
        return Ok([])
    }
    const data = { searchText: query }
    try {
        const res = await axios.post(ROOT_URL + "/search-airports", data)
        return Ok(res.data)
    } catch (err: any) {
        console.error(err)
        return Err(err)
    }
}

const searchAirlines = async (query: string): Promise<Result<Array<{ name: string, iata: string, ec261: boolean, id: string }>, Error>> => {
    // @ts-ignore
    if (query.length < 3 || query.length === 0 || query === 0) {
        return Ok([])
    }
    const data = { searchText: query }
    try {
        const res = await axios.post(ROOT_URL + "/search-airlines", data)
        return Ok(res.data)
    } catch (err: any) {
        console.error(err)
        return Err(err)
    }
}

const saveSignatureReq = async (url: string, caseId:string, token:string, paxId:string): Promise<Result<null, Error>> => {
    // Create data object
    const data = { caseId, docType: "signature", paxId, fileNames:["signature.png"] }
    // Create request url with token
    const reqUrl = ROOT_URL + '/get-upload-urls?secret_token=' + token
    // Make request
    try {
        // Step 1: Send request to server to get signed url
        const res = await axios.post(reqUrl, data)
        // Step 2: Upload file to signed url
        // Get signed url, which is the first element in the array
        const signedUrl = res.data.signedUrls[0]
        // Read file from url
        let file = await (await fetch(url)).arrayBuffer()
        // Upload file to signed url
        await axios.put(signedUrl, file, {
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        })
        return Ok(null)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

const saveAccountNumber = async (token:string, caseId: string, regNumber: string, accountNumber: string): Promise<Result<string, Error>> => {
    const data = {
        caseId,
        regNumber,
        accountNumber
    }
    const reqUrl = ROOT_URL + '/set-account-number?secret_token=' + token
    const res = await axios.post(reqUrl, data)
    const caseUrl = res.data
    if (res.status === 200)
      return Ok(caseUrl)
    else 
      return Err(new Error("Failed to save account number to database"))
}

const getClaimAmount = async (iata1: string, iata2: string): Promise<Result<number, Error>> => {
    try {
        const reqUrl = ROOT_URL + "/get-claim-amount"
        const res = await axios.post(reqUrl, { iata1, iata2 })
        return Ok(res.data.claimAmount)
    } catch (e: any) {
        return Err(e)
    }
}

const sendMagicLink = async (email: string): Promise<Result<null, Error>> => {
    try {
        await axios.post(`${ROOT_URL}/case-view/sendmail?email=${email}`)
        return Ok(null)
    } catch (e: any) {
        console.error(e)
        return Err(e)
    }
}

type Case = any | null
const getCassesApi = async (secret_token: string): Promise<Case> => {
    try {
        // Get data
        let data: any = await axios.get(`${ROOT_URL}/case-view/case-data`, 
                                        { params: { secret_token } }) // FIXME: Needs the ICase which should be in the enums
        let pax: any = await axios.get(`${ROOT_URL}/case-view/pax-data`, { params: { secret_token } }) // FIXME: pax should also be in the enums

        const dataRows = data.data.case
        const paxData = pax.data

        // Should be a better way to determine this
        const paxOwner = paxData[0]
        return {
            case: dataRows,
            owner: paxOwner,
            passengers: paxData,
            status: data.data.status
        }
    } catch (e) {
        return null
    }
}

const healthcheckApi = async (): Promise<Result<null, Error>> => {
    try {
        await axios.get(`${ROOT_URL}/healthcheck`)
        return Ok(null)
    } catch (e: any) {
        return Err(e)
    }
}

export { saveCaseData, getCaseId, saveSignatureReq, searchAirlines, searchAirports, sendUploadRequest, saveAccountNumber, sendMagicLink, getClaimAmount, getCassesApi, healthcheckApi }
