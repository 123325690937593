import React, { useEffect, useState, ReactNode } from 'react'
// import { Stepper, Step, StepLabel } from "@mui/material"
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material"

import { IsPresentPage
       , DelayMessagePage
       , CancellationButInformedPage 
       , ChooseFlightPage
       , ThreeHourPage
       } from "../RadioButtonPages/RadioButtonPages"
import RouteInfo from "../RouteInfo/RouteInfo"
import AskStopOver from "../AddStopOver/AskStopOver"
import SpecificFlightPage from "../SpecificFlightPage/SpecificFlightPage"
import ContactPage from "../ContactPage/ContactPage"
import FinishButtonPage from "../FinishButtonPage/FinishButtonPage"
import {
        DidRebookPage,
        DidFlyRebookPage,
        HasRebookPage,
        EndFlightInfoPage,
        UploadRebookPage,
        NoDocsPage
       } from "../RebookingPage/RebookingPage"
import {
       NoRebookPage,
       GotRefundTicketPage,
       UploadOriginalFlightPage,
       UploadReceiptPage,
       NewFlightInfoPage,
       RefundInputPage
       } from "../NoRebookingPage/NoRebookingPage"
import ExtraExpensePage from "../ExtraExpensePage/ExtraExpensePage"
import PaxExpensePayout from "../ContactPage/PaxExpensePayout"
import CompensationInputPage from "../CompensationInputPage/CompensationInputPage"
import SignaturePage from "../SignaturePage/SignaturePage"
import IsPresentDialog from "../Dialogs/IsPresentDialog"
import NonEUToEUWithNonEUAirlineDialog from "../Dialogs/NonEUToEUWithNonEUAirline"
import UploadBookingConfirmationPage from "../UploadBookingConfirmationPage/UploadBookingConfirmationPage";
import AccountNumberPage from "../AccountNumberPage/AccountNumberPage"
import ConfettiPage from "../Confetti/ConfettiPage"
import FirstCarrierPage from "../FirstCarrierPage/FirstCarrierPage"

import { useAppSelector, useAppDispatch } from '../../../hooks'

import { setReason } from "../Reason/ReasonSlice"
import ReasonType from "../../../enums/ReasonType"

import { setRoot, setNum } from "./CompositionSlice"

import { useMediaQuery } from "react-responsive"
import EuNoCompensationDialog from "../SpecificFlightPage/EuNoCompensation";
import DkNoCompensationDialog from "../SpecificFlightPage/DkNoCompensation";

import { StepCounter } from "../../basic/StepCounter"
import FormContainer from "../../basic/FormContainer"

import store from '../../../store'
import getGraph, {Graph, Page} from "../../../flowGraph"

import { healthcheckApi } from '../../../requests'
import { HasRebookNoFlyPage } from '../RebookingPage/NoRebookFlight'
import LessThanThreeHourDialog from '../Dialogs/LessThanThreeHourDialog'

function CompositionPage(props:{reason: ReasonType}) {
    const dispatch = useAppDispatch()

    // Health check
    healthcheckApi().then((res) => {
      // console.log(res)
    }).catch((err) => {
      console.error(err)
    })

    // Route data
    const pageTable: { [id: string]: ReactNode } = {
      delayMsg: <DelayMessagePage />,
      confetti: <ConfettiPage />,
      threeHour: <ThreeHourPage />,
      lessThanThreeHour: <LessThanThreeHourDialog />,
      isPresent: <IsPresentPage />,
      isPresentError: <IsPresentDialog />,
      cancellationButInformed: <CancellationButInformedPage  />,
      routeInfo: <RouteInfo />, 
      addStopOver: <AskStopOver />,
      chooseFlight: <ChooseFlightPage />,
      specificFlight: <SpecificFlightPage />,
      euNoCompensation: <EuNoCompensationDialog />,
      dkNoCompensation: <DkNoCompensationDialog />,
      contact: <ContactPage />,
      signature: <SignaturePage />,
      uploadBooking: <UploadBookingConfirmationPage />,
      
      didRebook: <DidRebookPage />,
      didFlyRebook: <DidFlyRebookPage />,
      hasRebook: <HasRebookPage />,

      hasRebookNoFly: <HasRebookNoFlyPage />,

      uploadRebook: <UploadRebookPage />,
      endFlightInfo: <EndFlightInfoPage />,
      noDocs: <NoDocsPage />,
      noRebook: <NoRebookPage />,
      gotRefundTicket: <GotRefundTicketPage />,
      refundInput: <RefundInputPage />,
      originalFlight: <UploadOriginalFlightPage />,
      receipt: <UploadReceiptPage />,
      newFlightInfo: <NewFlightInfoPage />,
      compensationInput: <CompensationInputPage />,
      compensationInputSimple: <CompensationInputPage onlyCompensation />,
      extraExpense: <ExtraExpensePage />,

      accountNumber: <AccountNumberPage />,

      // paxExpensePayout: <PaxExpensePayout />,

      finishButton: <FinishButtonPage />,
      firstCarrier: <FirstCarrierPage />,
      nonEUToEUWithNonEUAirline: <NonEUToEUWithNonEUAirlineDialog />,
    }

    const [stepTable, setStepTable] = useState<{ [key: string]: number }>({})

    const { pages, num, end } = useAppSelector(state => state.composition)
    const { passengers } = useAppSelector(state => state.contact)
    useEffect(() => {
      if (pages.length === 0) {
        dispatch(setRoot(props.reason))
        dispatch(setReason(props.reason))

        // Compute max steps for each page
        const graph = getGraph(store.getState())
        let stepTable : { [key: string]: number } = {}
        for (const [key, page] of Object.entries(graph)) {
          const maxSteps = getMaxSteps(page, graph)
          // Check if maxsteps is -Infinity, if so, then it is a leaf node
          if (maxSteps === -Infinity)
            stepTable[key] = 0
          else 
            stepTable[key] = maxSteps
        }
        setStepTable(stepTable)
      }
    }, [dispatch, pages.length, props.reason])

    // const isDesktopOrLaptop = useMediaQuery({
    //   query: '(min-width: 1224px)'
    // })
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
    return <div className="header-push-down compositionPage">
             {/* { isBigScreen || isDesktopOrLaptop ? */}
               <div>
                   <div style={{position: "sticky", zIndex: 1300, top: "0"}}>
                    <FormContainer boxShadow={true}>
                       { /* Set the step counter to have the num from the slice, with the end number being end times the lenght of the number of pax unless if there are no pax, then just set it to 1 */ }
                       <SideStepper page={pages[pages.length - 1]} numSteps={pages.length} stepTable={stepTable} isMobile={isMobile} />
                       <StepCounter num={num} 
                                    end={end * (passengers.length ? passengers.length : 1)} 
                                    moreThanOnePax={passengers.length ? passengers.length > 1 : false}
                                    onFinished={() => { dispatch(setNum(end)) }}
                                    isMobile={isMobile}
                                    />
                    </FormContainer>
                   </div>
                 <div style={{flex: "0 0 80%"}}>
                 { pages.map(x => <div key={x}> {pageTable[x]} </div>) }
                 </div>
               </div>
               {/* :
               <React.Fragment>
                { pages.map(x => <div key={x}> {pageTable[x]} </div>) }
               </React.Fragment> */}
             {/* } */}
           </div>
}

function getMaxSteps(page: Page, graph: Graph): number{
  return Math.max(...(page.nextPages.map(x => {
    const page = graph[x.name]
    if (page.nextPages.length === 0)
      return 0
    else 
      return 1 + getMaxSteps(page, graph)
  })))
}


function getStepPage(page: string, stepTable: { [key: string]: number }, curr: number): number {
  if (page === undefined) return 0
  const maxSteps = stepTable[page]
  return curr/(curr + maxSteps)*100
}

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number, isMobile: Boolean }) => {
  const marginTop = props.isMobile ? -2 : 0
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: marginTop }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}


const SideStepper = (props: { page: string, numSteps: number, stepTable: { [key: string]: number },  isMobile: Boolean }) => {
    return <LinearProgressWithLabel value={getStepPage(props.page, props.stepTable, props.numSteps)} isMobile={props.isMobile} />
}

export default CompositionPage
