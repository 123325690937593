import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface ConfettiState {
    confettiContinue: boolean
}

// Define the initial state using that type
const initialState: ConfettiState = {
    confettiContinue: true
}

export const confettiSlice = createSlice({
    name: 'confetti',
    initialState,
    reducers: {
        clear: (state) => {
            state.confettiContinue = true
        }
    }
})

export const { clear } = confettiSlice.actions

export default confettiSlice.reducer
