import React from 'react';
// @ts-ignore
import SignaturePad from 'react-signature-canvas'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Typography,
    Link
} from "@mui/material"
import PersonDataDialog from "../../basic/PersonDataDialog"
import TermsDialog from "./TermsDialog"
import { useAppSelector } from '../../../hooks';
import { Pax } from '../../../enums/entities/Pax';
import POADialog from "./POADialog"
function SignatureComponent(props:{onSave(url:string) : void, disabled:boolean, pax:Partial<Pax>}) {
    const [checked, setChecked] = React.useState(false)
    const [errChecked, setErrChecked] = React.useState(false)

    // Get data from Redux state
    const specificFlight = useAppSelector(state => state.specificFlight);
    const { num: paxPayoutCompensation } = useAppSelector(state => state.composition);
    const passengers = useAppSelector(state => state.contact.passengers);

    // Calculate compensation amounts
    const fullCompensationAmount = Math.round(paxPayoutCompensation * 1.25);
    const feeAmount = Math.round(fullCompensationAmount * 0.2);

    // Format flight date and number
    const flightDate = specificFlight.date ? 
        new Date(specificFlight.date).toLocaleDateString('en-GB') : '';
    const flightNumber = `${specificFlight.flightNumPrefix}${specificFlight.flightNum}`;

    // Format pax name
    const paxName = props.pax.firstName && props.pax.lastName ? 
        `${props.pax.firstName} ${props.pax.lastName}` : '';

    let sigPad:any = {};
    const saveData = () => {
        if (checked && !sigPad.isEmpty()) {
            const url = sigPad.getCanvas().toDataURL('image/png')
            // Call on save function
            props.onSave(url)
            // Clear the signature pad and reset errChecked
            setErrChecked(false)
            setChecked(false)
            sigPad.clear()
            return
        }
        setErrChecked(true)
        return
    }

    return (
        <Grid container spacing={1} alignItems="center" justifyContent="center">
            <Grid item md={12}>
                <SignaturePad canvasProps={{className: 'sigPad'}}
                              clearOnResize={false}
                              ref={(ref:any) => { sigPad = ref }} />
            </Grid>
            <Grid item md={12}>
                <FormControl error={false}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox
                            checked={checked}
                            onChange={() => {setChecked(!checked)}}
                        />}
                                          label={
                                              <Typography align="center">
                                                  Jeg accepterer <a href="https://flysag.dk/privacy-policy/" target="_blank" rel="noopener noreferrer">Persondatapolitik</a>, {' '}
                                                  <TermsDialog 
                                                      flightNumber={flightNumber}
                                                      flightDate={flightDate}
                                                      paxPayoutCompensation={paxPayoutCompensation}
                                                      fullCompensationAmount={fullCompensationAmount}
                                                      feeAmount={feeAmount}
                                                      paxName={paxName}
                                                  />{' '}
                                                  og <POADialog
                                                      flightNumber={flightNumber}
                                                      flightDate={flightDate}
                                                      paxPayoutCompensation={paxPayoutCompensation}
                                                      fullCompensationAmount={fullCompensationAmount}
                                                      feeAmount={feeAmount}
                                                      paxName={paxName}
                                                  />
                                              </Typography>
                                          }/>
                    </FormGroup>
                    {errChecked && <FormHelperText error={errChecked}>Underskriv først og accepter vilkår for at gemme</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item md={4}>
                <Button disabled={props.disabled} variant="outlined" onClick={
                    () => sigPad.clear()
                }>Ryd underskrift</Button>
            </Grid>
            <Grid item md={4}>
                <Button disabled={props.disabled} variant="contained" onClick={saveData}>Gem</Button>
            </Grid>
        </Grid>
    )
}

export default SignatureComponent
