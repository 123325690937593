import React, { useEffect, useRef, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import BlurredDialog from "../../basic/BlurredDialog"
import { Box, Typography, Alert, AlertTitle, Grow, Snackbar } from "@mui/material"

const ConfettiPage: React.FC = () => {
    const { width, height } = useWindowSize()
    const [isOpen, setIsOpen] = useState<boolean>(true)

    const handleClose = () => {
        setIsOpen(false)
    }

    return <>
          <Snackbar open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={5000}>
            <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
                <AlertTitle>Tillykke!</AlertTitle>
                  <Typography align="center" gutterBottom>
                      Det ser ud til, at du er berettiget!                
                  </Typography>
                  <Typography align="center" gutterBottom>
                      Udfyld resten af formularen for at oprette din sag.
                  </Typography>
            </Alert>
          </Snackbar>
          <Box sx={{ position: 'fixed', top: 0, left: 0, zIndex: 9999 }}>
            <Confetti
                width={width}
                height={height}
                numberOfPieces={200}
                recycle={false}
                confettiSource={{
                    x: width / 2,
                    y: 0,
                    w: 0,
                    h: 0
                }}
            />
          </Box>
    </>
}

export default ConfettiPage
