import React, { ChangeEvent, useState } from 'react'
import { Pax } from "../../../enums/entities/Pax"
import { useAppSelector, useAppDispatch } from '../../../hooks'
import TextField from '@mui/material/TextField'
import { Grid, Icon, Tooltip, Typography } from '@mui/material'
import OverEightToggle from './OverEightToggle'
import { changeEmail, changePhoneNumber } from "./ContactSlice"
import { validateString, validatePhoneNumber, validateEmail, validateCpr } from "./validationFunctions"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const validate = (value: string | undefined, checked:boolean): boolean => {
    if (checked) return validateString(value)
    else return true
}

const validateSpecial = (validated:boolean, checked:boolean): boolean => {
    if (checked) return validated
    else return true
}

interface ContactInfoProps {
    pax: Partial<Pax>,
    i: number,
    checked: boolean,

    // FIXME: Give the type from ContactPage
    onChange: (item: any) => (e: ChangeEvent<HTMLInputElement>) => void
    onSwitchChange: (e: ChangeEvent<HTMLInputElement>) => void

    // Email and phone number
    email: string,
    setEmail: (email: string) => void,
    phoneNumber: string,
    setPhoneNumber: (phoneNumber: string) => void

}


const ContactInfo = (props: ContactInfoProps) => {
    // const emailVal = useAppSelector(state => state.contact.email)
    // const phoneNumberVal = useAppSelector(state => state.contact.phoneNumber)
    const emailVal = props.email
    const phoneNumberVal = props.phoneNumber
    const { pax, onChange } = props
    const dispatch = useAppDispatch()
    return  <Grid container spacing={4} alignItems="center" justifyContent="center">
                 <Grid item md={6} xs={12}>
                     <TextField
                         value={pax.firstName}
                         onChange={onChange("firstName")}
                         label="Fornavn"
                         error={!validate(pax.firstName, props.checked)}
                         variant="outlined"
                         fullWidth
                     />
                 </Grid>
                 <Grid item md={6} xs={12}>
                     <TextField
                         value={pax.lastName}
                         onChange={onChange("lastName")}
                         label="Efternavn"
                         variant="outlined"
                         error={!validate(pax.lastName, props.checked)}
                         fullWidth
                     />
                 </Grid>
                {/* {

                    (props.i === 0) && <Grid item md={6} xs={12}> */}
                    <Grid item md={props.i === 0 ? 6 : 12} xs={12}>
                        <TextField
                            value={pax.email}
                            // onChange={(e: ChangeEvent<HTMLInputElement>) => {dispatch(changeEmail(e.target.value))}}
                            // onChange={(e: ChangeEvent<HTMLInputElement>) => {props.setEmail(e.target.value)}}
                            onChange={onChange("email")}
                            label="Email"
                            error={!validateSpecial(validateEmail(pax.email),props.checked)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                {

                (props.i === 0) 
                    && <Grid item md={6} xs={12}>
                        <TextField
                            value={pax.phoneNumber}
                            // onChange={(e: ChangeEvent<HTMLInputElement>) => {dispatch(changePhoneNumber(e.target.value))}}
                            // onChange={(e: ChangeEvent<HTMLInputElement>) => {props.setPhoneNumber(e.target.value)}}
                            onChange={onChange("phoneNumber")}
                            label="Telefonnummer"
                            variant="outlined"
                            error={!validateSpecial(validatePhoneNumber(pax.phoneNumber),props.checked)}
                            fullWidth
                        />
                    </Grid>
                }
                <Grid item md={12} xs={12}>
                    <OverEightToggle
                        pax={pax}
                        checked={props.checked}
                        onSwitchChange={props.onSwitchChange}
                        onGuardianFirstNameChange={props.onChange("guardianFirstName")}
                        onGuardianLastNameChange={props.onChange("guardianLastName")}
                        onGuardianCprNumberChange={props.onChange("guardianCprNumber")}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                     <TextField
                         value={pax.address}
                         onChange={onChange("address")}
                         label={
                          <span>Adresse (valgfri)*</span>
                         }
                         variant="outlined"
                         fullWidth
                     />
                 </Grid>
                 <Grid item md={7} xs={12}>
                     <TextField
                         value={pax.city}
                         onChange={onChange("city")}
                         label={
                          <span>By (valgfri)*</span>
                         }
                         variant="outlined"
                         fullWidth
                     />
                 </Grid>
                 <Grid item md={5} xs={12}>
                     <TextField
                         value={pax.postCode}
                         onChange={onChange("postCode")}
                         label={
                          <span>Postnummer (valgfri)*</span>
                         }
                         variant="outlined"
                         fullWidth
                     />
                 </Grid>
                 <Grid item md={12} xs={12}>
                     <TextField
                         value={pax.country}
                         onChange={onChange("country")}
                         label={
                            <span>Land (valgfri)*</span>
                         }
                         variant="outlined"
                         fullWidth
                     />
                 </Grid>
                 <Grid item md={12} xs={12}>
                     <TextField
                         value={pax.cprNumber}
                         onChange={onChange("cprNumber")}
                         error={!validateSpecial(validateCpr(pax.cprNumber), props.checked)}
                         label= {
                            <span>CPR nummer (valgfri)*</span>
                         }
                         variant="outlined"
                         fullWidth
                         helperText="* Vi anbefaler, at du indtaster disse felter for en hurtigere og smidigere sagsbehandling"
                     />
                 </Grid>
                 {/* <Grid item md={1} xs={1}>
                    <Tooltip enterTouchDelay={0} title="Indtastning af dit CPR-nummer er valgfri på dette tidspunkt, men bemærk venligst, at hvis det ikke leveres nu, kan det forsinke sagsbehandlingen; hvis vi fortsætter med at indgive en stævning, vil vi kræve det fra dig på et senere tidspunkt.">
                        <HelpOutlineIcon/>
                    </Tooltip>
                 </Grid> */}
             </Grid>
}

export default ContactInfo;
