import { Answer } from "./enums/utils"
// import { NoRebookingChoice } from "./components/flow/NoRebookingPage/NoRebookingSlice"
import NoRebookingChoice from "./enums/NoRebookingChoice"
import { clear as delayMsgClear } from "./components/flow/RadioButtonPages/DelayMessageSlice"
import { clear as threeHourClear, Answer as threeHourAnswer } from "./components/flow/RadioButtonPages/ThreeHourSlice"
import { clear as isPresentClear } from "./components/flow/RadioButtonPages/IsPresentSlice"
import { clear as routeClear, isNonEUtoEU } from "./components/flow/RouteInfo/RouteSlice"
import { clear as addStopOverClear } from "./components/flow/AddStopOver/AddStopOverSlice"
import { clear as chooseFlightClear } from "./components/flow/ChooseFlight/ChooseFlightSlice"
import { clear as specificFlightClear } from "./components/flow/SpecificFlightPage/SpecificFlightSlice"
import { clear as contactClear } from "./components/flow/ContactPage/ContactSlice"
import { clear as signatureClear } from "./components/flow/SignaturePage/SignatureSlice"
import { clear as compensationInputClear } from "./components/flow/CompensationInputPage/CompensationInputSlice"
import { clear as uploadBookingClear } from "./components/flow/UploadBookingConfirmationPage/UploadBookingConfirmationSlice"
import { clear as accountNumberClear } from "./components/flow/AccountNumberPage/AccountNumberSlice"
import { clear as firstCarrierClear } from "./components/flow/FirstCarrierPage/FirstCarrierSlice"

import {
        didRebookClear,
        didFlyRebookClear,
        hasRebookClear,
        endFlightInfoClear,
        rebookClear,
        noDocsClear,
        hasRebookNoFlyClear
       } from "./components/flow/RebookingPage/RebookingSlice"

import {
        noRebookClear,
        gotRefundTicketClear,
        originalFlightClear,
        receiptClear,
        newFlightInfoClear,
        refundClear
       } from "./components/flow/NoRebookingPage/NoRebookingSlice"

import type { RootState } from "./store"
import { HasRebookNoFlyPage } from "./components/flow/RebookingPage/NoRebookFlight"
import { clear as confettiClear } from "./components/flow/Confetti/ConfettiSlice"

export interface Page {
  nextPages: Array<{ name: string, shouldContinue: boolean }>,
  clear: (() => void) | null
}

export interface Graph { [key: string]: Page }

function graph (state: RootState): Graph {
    // Determine root
    // Generate flow graph and return it
    return {

      // Simple part
      delayMsg: {
        nextPages: [{ name: "routeInfo", shouldContinue: (state.delayMessage.answer !== null) }],
        // nextPages: [{ name: "confetti", shouldContinue: (state.delayMessage.answer !== null) }],
        clear: delayMsgClear
      },
      isPresent: {
        nextPages: [ { name: "routeInfo", shouldContinue: (state.isPresent.answer === Answer.Yes) }
                   , { name: "isPresentError", shouldContinue: (state.isPresent.answer === Answer.No) }],
        clear: isPresentClear
      },
      isPresentError: {
        nextPages: [],
        clear: null
      },
      threeHour: {
        nextPages: [ { name: "confetti", shouldContinue: (state.threeHour.answer !== threeHourAnswer.LessThanTwo && state.threeHour.answer !== null) } 
                   , { name: "lessThanThreeHour", shouldContinue: (state.threeHour.answer === threeHourAnswer.LessThanTwo) && (state.threeHour.answer !== null) } ],
        clear: threeHourClear
      },
      lessThanThreeHour: {
        nextPages: [],
        clear: null
      },
      // cancellationButInformed: {
        // nextPages: [{ name: "routeInfo", shouldContinue: (state.cancellationButInformed.answer !== null) }],
        // clear: cancellationButInformedClear
      // },
      routeInfo: {
        nextPages: [ 
                     { name: "threeHour", shouldContinue: (state.route.isValidated && !isNonEUtoEU(state.route)) }
                   , { name: "firstCarrier", shouldContinue: (state.route.isValidated && isNonEUtoEU(state.route)) }
                   ],
        clear: routeClear
      },
      firstCarrier: {
        // double !! is used to convert null and undefined to boolean (because of type checking)
        nextPages: [ 
                    { name: "threeHour", shouldContinue: (state.firstCarrier.isValidated && !!state.firstCarrier.airline?.ec261) },
                    { name: "nonEUToEUWithNonEUAirline", shouldContinue: (state.firstCarrier.isValidated && !state.firstCarrier.airline?.ec261) }
                  ],
        clear: firstCarrierClear
      },
      nonEUToEUWithNonEUAirline: {
        nextPages: [],
        clear: null
      },
      confetti: {
        nextPages: [{ name: "addStopOver", shouldContinue: state.confetti.confettiContinue }],
        clear: confettiClear
      },
      addStopOver: {
        nextPages: [  { name: "chooseFlight", shouldContinue: (state.addStopOver.stopOverArray.length !== 0 && state.addStopOver.addStopOverContinue) },
                      { name: "specificFlight", shouldContinue: (state.addStopOver.stopOverArray.length === 0 && state.addStopOver.addStopOverContinue) }
                   ],
        clear: addStopOverClear
      },
      chooseFlight: {
        nextPages: [{ name: "specificFlight", shouldContinue: (state.chooseFlight.flight !== null) }],
        clear: chooseFlightClear
      },
      specificFlight: {
        nextPages: [
            { name: "uploadBooking", shouldContinue: (state.specificFlight.specificFlightContinue && state.specificFlight.isValidated) },
            { name: "euNoCompensation", shouldContinue: ((state.specificFlight.eu_eligible !== null) && !state.specificFlight.eu_eligible && !state.specificFlight.dk_eligible) },
            { name: "dkNoCompensation", shouldContinue: ((state.specificFlight.eu_eligible !== null) && state.specificFlight.eu_eligible && !state.specificFlight.dk_eligible) },
        ],
        clear: specificFlightClear
      },
      euNoCompensation: {
          nextPages: [],
          clear: null
      },
      dkNoCompensation: {
          nextPages: [],
          clear: null
      },
      uploadBooking: {
          nextPages: [{ name: "didRebook", shouldContinue: (state.uploadBooking.isValidated) }],
          clear: uploadBookingClear
      },

      // Rebooking part
      didRebook: {
        nextPages: [ { name: "didFlyRebook", shouldContinue: (state.rebooking.didRebook === Answer.Yes) }
                   , { name: "noRebook", shouldContinue: (state.rebooking.didRebook === Answer.No) } ],
        clear: didRebookClear
      },
      didFlyRebook: {
        nextPages: [ { name: "endFlightInfo", shouldContinue: (state.rebooking.didFlyRebook === Answer.Yes) } 
                  //  , { name: "noRebook", shouldContinue: (state.rebooking.didFlyRebook === Answer.No)} ],
                   , { name: "hasRebookNoFly", shouldContinue: (state.rebooking.didFlyRebook === Answer.No)} ],
        clear: didFlyRebookClear
      },
      hasRebook: {
        nextPages: [ { name: "uploadRebook", shouldContinue: (state.rebooking.hasRebook === Answer.Yes) }
                   , { name: "compensationInput", shouldContinue: (state.rebooking.hasRebook === Answer.No && state.rebooking.endFlightInfoValidated) }
                   , { name: "noRebook", shouldContinue: (state.rebooking.hasRebook === Answer.No && !state.rebooking.endFlightInfoValidated) } ],
        clear: hasRebookClear
      },

      hasRebookNoFly: {
        nextPages: [
            { name: "endFlightInfo", shouldContinue: (state.rebooking.hasRebookNoFly === Answer.Yes) },
            { name: "noRebook", shouldContinue: (state.rebooking.hasRebookNoFly === Answer.No) }
          ],
        clear: hasRebookNoFlyClear
      },

      uploadRebook: {
        nextPages: [
          { name: "compensationInput", shouldContinue: (state.rebooking.rebookingFileValidated) && (state.rebooking.hasRebookNoFly === Answer.No || state.rebooking.hasRebookNoFly === null)},
          { name: "noRebook", shouldContinue: (state.rebooking.rebookingFileValidated && state.rebooking.hasRebookNoFly === Answer.Yes)}
        ],
        clear: rebookClear
      },
      endFlightInfo: {
        nextPages: [{ name: "hasRebook", shouldContinue: (state.rebooking.endFlightInfoValidated && state.rebooking.endFlightInfoContinue) }],
        clear: endFlightInfoClear
      },
      noDocs: {
        nextPages: [
          { name: "compensationInput", shouldContinue: (state.rebooking.noDocsIsValidated && state.rebooking.noDocsContinue && state.rebooking.hasRebookNoFly === Answer.No) },
          { name: "noRebook", shouldContinue: (state.rebooking.noDocsIsValidated && state.rebooking.noDocsContinue && state.rebooking.hasRebookNoFly === Answer.Yes) }
        ],
        clear: noDocsClear
      },

      // NoRebooking part
      noRebook: {
        nextPages: [ { name: "gotRefundTicket", shouldContinue: (state.noRebooking.noRebook === NoRebookingChoice.NeverTraveled) } 
                   , { name: "newFlightInfo", shouldContinue: (state.noRebooking.noRebook === NoRebookingChoice.OwnTicket) }
                   , { name: "compensationInput", shouldContinue: (state.noRebooking.noRebook === NoRebookingChoice.OriginalFlight)} ],
        clear: noRebookClear
      },
      gotRefundTicket: {
        nextPages: [ { name: "refundInput", shouldContinue: (state.noRebooking.gotRefundTicket === Answer.Yes) } 
                   , { name: "originalFlight", shouldContinue: (state.noRebooking.gotRefundTicket === Answer.No) }],
        clear: gotRefundTicketClear
      },
      refundInput: {
        nextPages: [ { name: "compensationInputSimple", shouldContinue: (state.noRebooking.refundValidated && state.noRebooking.refundContinue) } ],
        clear: refundClear
      },

      originalFlight: {
        nextPages: [ { name: "compensationInputSimple", shouldContinue: (state.noRebooking.originalFlightValidated) } ],
        clear: originalFlightClear
      },

      receipt: {
        nextPages: [ { name: "compensationInput", shouldContinue: (state.noRebooking.receiptValidated) } ],
        clear: receiptClear
      },
      newFlightInfo: {
        nextPages: [ { name: "receipt", shouldContinue: (state.noRebooking.newFlightInfoValidated && state.noRebooking.newFlightInfoContinue) } ],
        clear: newFlightInfoClear
      },

      // Final part
      compensationInput: {
        nextPages: [ { name: "contact", shouldContinue: (state.compensationInput.isValidated && state.compensationInput.compensationInputContinue) } ],
        clear: compensationInputClear
      },
      compensationInputSimple: {
        nextPages: [ { name: "contact", shouldContinue: (state.compensationInput.isValidated && state.compensationInput.compensationInputContinue) } ],
        clear: compensationInputClear
      },
      contact: {
          nextPages: [{ name: "signature", shouldContinue: (state.contact.isValidated) }],
          clear: contactClear
      },
      signature: {
        nextPages: [{ name: "accountNumber", shouldContinue: (state.signature.isSigned) }],
        clear: signatureClear
      },
      accountNumber: {
        // nextPages: [ { name: "finishButton", shouldContinue: (state.accountNumber.isValidated) } ],
        nextPages: [ { name: "finishButton", shouldContinue: true } ],
        clear: accountNumberClear
      },

      finishButton: {
        nextPages: [],
        clear: null
      },
    }
}

export default graph
